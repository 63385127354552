<template>
  <div class="news-detail">
    <sub-banner :title="banner.title" :subTitle="banner.subTitle" :bannerBg="banner.bannerBg"></sub-banner>
    <van-skeleton title :row="5" :loading="loading">
      <div class="news-content">
        <hgroup>
          <h3>{{info.title}}</h3>
          <h4>{{info.createdAt}}</h4>
        </hgroup>
        <div class="detail" v-html="info.content"></div>
        <div class="change-news">
          <router-link v-if="info.pre"
                       :to="'/company/news/detail?id='+info.pre.contentItemId"
                       :title="info.pre.title"
                       class="pre">上一篇：{{info.pre.title}}
          </router-link>
          <router-link v-if="info.next"
                       :to="'/company/news/detail?id='+info.next.contentItemId"
                       :title="info.next.title"
                       class="next">下一篇：{{info.next.title}}
          </router-link>
        </div>
      </div>
    </van-skeleton>
  </div>
</template>
<script>
import pageMixin from '@/mixins/pageMixin'
import subBanner from '@/components/subBanner'
export default {
  name: 'newsDetail',
  mixins: [pageMixin],
  components: {
    subBanner
  },
  data () {
    return {
      banner: {
        title: '资讯详情',
        subTitle: 'Information for details',
        bannerBg: require('@/assets/images/news/banner_detail.jpg')
      },
      loading: true,
      info: {}
    }
  },
  created () {
    this.fetchDetail()
  },
  watch: {
    '$route' () {
      this.fetchDetail()
    }
  },
  methods: {
    fetchDetail () {
      const { id } = this.$route.query
      if (!id) return
      this.loading = true
      this.api.fetchNewsContentDetail(id).then(data => {
        this.info = data
      }).catch(() => {
        // this.$router.replace('/404')
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/ .van-skeleton{
    padding: 50px 40px;
  }
  .news-detail{
    .news-content{
      min-height: 50vh;
      padding: 50px 40px;
      color: @grey;
      hgroup{
        margin-bottom: 35px;
        font-size: 28px;
        text-align: center;
        h3{
          margin-bottom: 19px;
          line-height: 44px;
          color: @black;
          font-weight: 600;
        }
      }
      /deep/ .detail{
        p{
          line-height: 44px;
          color: @grey;
          font-size: 24px;
        }
        img{
          max-width: 100%;
        }
      }
    }
    .change-news{
      padding-top: 80px;
      line-height: 36px;
      a{
        display: block;
        max-width: 100%;
        .textEllipsisSingle;
        margin-bottom: 20px;
        font-size: 26px;
      }
    }
  }
</style>
